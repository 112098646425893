<template>
    <el-dialog
        title="修改职位状态"
        :visible.sync="dialogVisible"
        :width="type=='single'?'480px':'600px'"
        v-loading="loading"
        :show-close="false"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        append-to-body
    >
        <div class="status-content-wrap" v-loading="loading">
            <p class="content-text">
                你正在修改<span>{{ jobNames }}</span
                ><b v-if="jobLength>1">等<i>{{jobLength}}</i>个职位</b>的职位状态
            </p>
            <div class="items-row">
                <span class="options-label">职位状态</span>
                <el-radio-group v-model="jobStatusValue" size="mini" @input="handleSelectStatus">
                    <el-radio-button v-if="type=='batch'" :label="-1">
                        不变
                    </el-radio-button>
                    <el-radio-button
                        v-for="(option, index) in type == 'batch' ? jobStatusOptionsBatch : jobStatusOptions"
                        :key="index"
                        :label="option.value"
                        >{{ option.text }}</el-radio-button
                    >
                </el-radio-group>
            </div>
            <div class="items-row bind-project-row" v-if="isCFUser && !showBindDepartmentTip && jobStatusValue == 1 && (hasBindList.length == 0)">
                <span class="options-label">绑定项目</span>
                <project-select
                    class="col-4"
                    ref="projectSelect"
                    placeholder="请输入所属项目"
                    @projectSelected="changeProject"
                ></project-select>
                <span
                    class="add-customer-btn"
                    @click="$emit('showProjectCreateDialog')">
                    <i class="el-icon-circle-plus-outline"></i>
                    <em>添加项目</em>
                </span>
            </div>
            <div class="items-row bind-project-row" v-if="showBindDepartmentTip">
                <span class="options-label">绑定项目</span>
                <span class="bind-department-tip">
                    <i class="el-icon-warning"></i>
                    请先为职位绑定部门！
                </span>
            </div>
            <div class="items-row">
                <span class="options-label">紧急程度</span>
                <el-radio-group v-model="emergencyValue" size="mini">
                    <el-radio-button v-if="type=='batch'" :label="-1">
                        不变
                    </el-radio-button>
                    <el-radio-button
                        v-for="(option, index) in emergencyOptions"
                        :key="index"
                        :label="option.value"
                        :class="{ 'red-fill': option.value }"
                        >{{ option.text }}</el-radio-button
                    >
                </el-radio-group>
            </div>
            <div class="items-row">
                <span class="options-label">难易程度</span>
                <el-radio-group v-model="difficultyValue" size="mini">
                    <el-radio-button v-if="type=='batch'" :label="-1">
                        不变
                    </el-radio-button>
                    <el-radio-button
                        v-for="(option, index) in difficultyOptions"
                        :key="index"
                        :label="option.value"
                        :class="{ 'red-fill': option.value }"
                        >{{ option.text }}</el-radio-button
                    >
                </el-radio-group>
            </div>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取消</el-button>
            <el-button type="primary" @click="updateJobStatus" :disabled="showBindDepartmentTip">确定</el-button>
        </span>
    </el-dialog>
</template>
<script>
import myOrdersService from "#/js/service/myOrdersService.js";
import JobService from '#/js/service/jobService.js';
import ProjectSelect from '#/component/common/project-select.vue';
import ProjectService from "#/js/service/projectService.js";
import emitter from "@src/js/mixins/emitter";

export default {
    name: "jobStatusDialog",
    mixins: [emitter],
    components: {
        ProjectSelect,
    },
    props: {
    },
    data() {
        return {
            loading: false,
            jobStatusOptions: [
                {
                    value: 1,
                    text: "进行中"
                },
                {
                    value: 4,
                    text: "完成"
                },
                {
                    value: 2,
                    text: "暂停"
                }
            ],
            jobStatusOptionsBatch: [
                {
                    value: 4,
                    text: "完成"
                },
                {
                    value: 2,
                    text: "暂停"
                }
            ],
            emergencyOptions: [
                {
                    value: 1,
                    text: "紧急"
                },
                {
                    value: 0,
                    text: "不紧急"
                }
            ],
            difficultyOptions: [
                {
                    value: 1,
                    text: "较难"
                },
                {
                    value: 0,
                    text: "容易"
                }
            ],
            dialogVisible: false,
            jobStatusValue: "",
            emergencyValue: "",
            difficultyValue: "",
            checkedArr: [], //被选择的数据
            loading: false,
            type: "", //分为single和batch
            jobLength:0,
            jobId: "",
            customerId: "",
            customerDepartmentId: "",
            hasBindList: [],
            showBindDepartmentTip: false,
            bindProjectId: ''
        };
    },
    computed: {
        jobNames() {
            this.jobLength = this.checkedArr.length;
            if (this.checkedArr.length == 1) {
                return this.checkedArr[0].jobName ? this.checkedArr[0].jobName : this.checkedArr[0].name;
            } else {
                let jobNameArr = [];
                this.checkedArr.map(item => {
                    jobNameArr.push(item.jobName);
                });
                return jobNameArr.join("、");
            }
        },
        userInfo() {
            return this.$store.state.user.userInfo;
        },
        isCFUser() {
            return this.$store.state.user.userInfo.isCFUser;
        },
    },
    methods: {
        initDialog(json) {
            this.type = json.type;
            this.checkedArr = json.checkedArr;
            this.jobStatusValue = json.jobStatusValue;
            this.emergencyValue = json.emergencyValue;
            this.difficultyValue = json.difficultyValue;
            this.dialogVisible = true;
            this.jobId = json.jobId;
            this.customerId = json.customerId || '';
            this.customerDepartmentId = json.customerDepartmentId || '';
            this.showBindDepartmentTip = false;
            this.hasBindList = [];
            this.bindProjectId = '';

            if (this.type == 'batch') {
                return false;
            }

            this.$nextTick(() => {
                if (this.customerDepartmentId) {
                    this.getHasBindList();
                }
                if (this.jobStatusValue == 1) {
                    if (this.customerDepartmentId) {
                        this.initProjectSelect();
                    } else {
                        this.showBindDepartmentTip = true;
                    }
                }
            });
        },
        handleClose() {},

        getHasBindList() {
            this.loading = true;
            ProjectService.getJobAlreadyBindingProjects(this.jobId)
                .then((res) => {
                    res.forEach((el) => {
                        el.projectName = el.projectName.replace("$#$", "-");
                    });
                    this.hasBindList = res || [];
                    // console.log(this.hasBindList);
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        handleSelectStatus(value) {
            console.log(value);
            if (value == 1) {
                this.$nextTick(() => {
                    if (this.customerDepartmentId) {
                        this.initProjectSelect();
                    } else {
                        this.showBindDepartmentTip = true;
                    }
                });
            } else {
                this.showBindDepartmentTip = false;
            }
        },

        initProjectSelect() {
            this.$refs.projectSelect && this.$refs.projectSelect.getProjectList({
                customerId: this.customerId,
                customerDepartmentId: this.customerDepartmentId
            });
        },
        changeProject(project) {
            this.bindProjectId = project.projectId;
        },
        refreshPorjectList() {
            if(this.customerId && this.customerDepartmentId) {
                this.initProjectSelect();
            }
        },
        updateJobStatus() {
            if(this.type=='batch') {
                //职位状态更新
                // let dataJson = [];
                // this.checkedArr.forEach(item => {
                //     let itemObj = {
                //         jobId: item.jobId ? item.jobId : item.id,
                //         jobStatus:
                //             this.jobStatusValue==-1?null:this.jobStatusValue,
                //         emergencyDegree:
                //             this.emergencyValue==-1?null:this.emergencyValue,
                //         difficultDegree:
                //             this.difficultyValue==-1?null:this.difficultyValue
                //     };
                //     dataJson.push(itemObj);
                // });
                // myOrdersService
                //     .updateJobStatus(dataJson)
                //     .then(res => {
                //         this.loading = false;
                //         this.dialogVisible = false;
                //         shortTips("职位状态修改成功");
                //         if(this.type=='batch'){
                //             this.$emit('update-list');
                //         }else{
                //             this.checkedArr[0].status = this.jobStatusValue;
                //             this.checkedArr[0].emergencyDegree = this.emergencyValue;
                //             this.checkedArr[0].difficultDegree = this.difficultyValue;
                //             this.$emit('update-item',this.checkedArr[0]);
                //         }
                //     })
                //     .catch(err => {
                //         console.log(err);
                //         this.loading = false;
                //     });

                this.loading = true;

                let ids = [];
                this.checkedArr.forEach(item => {
                    // console.log(item);
                    ids.push(item.jobId ? item.jobId : item.id);
                });
                // console.log(ids);
                let params = {
                    ids: ids,
                    status: this.jobStatusValue == -1? null : this.jobStatusValue,
                    emergencyDegree: this.emergencyValue == -1 ? null : this.emergencyValue,
                    difficultDegree: this.difficultyValue == -1 ? null : this.difficultyValue,
                };
                myOrdersService.batchUpdateJobStatus(params) 
                    .then(res => {
                        this.loading = false;
                        this.dialogVisible = false;
                        shortTips("职位状态修改成功");
                        this.$emit('update-list');
                    }).catch(err => {
                        this.loading = false;
                        this.dialogVisible = false;
                        console.log(err);
                    })
            }   else {
                if (this.isCFUser
                    && this.customerDepartmentId 
                    && this.jobStatusValue == 1 
                    && this.hasBindList.length == 0
                ){
                    if (!this.bindProjectId && this.userInfo.isCFUser) {
                        shortTips('请选择要绑定的项目！');
                        return false;
                    } else {
                        if(this.userInfo.isCFUser) {
                            this.loading = true;
                            ProjectService.editProjectJobBindings({
                                JobId: this.jobId,
                                ProjectIds: [this.bindProjectId],
                            }).then((bindRes) => {
                                this.updateJobStatusSingle();
                            }).catch(bindErr => {
                                console.log(bindErr);
                                this.loading = false;
                            });
                        } else {
                            this.updateJobStatusSingle();
                        }
                    }
                } else {
                    this.loading = true;
                    this.updateJobStatusSingle();
                }
            }
        },
        updateJobStatusSingle() {
            let params = {};
            this.checkedArr.forEach(item => {
                params = {
                    id: item.jobId ? item.jobId : item.id,
                    status: this.jobStatusValue,
                    emergencyDegree: this.emergencyValue,
                    difficultDegree: this.difficultyValue
                };
            });
            // console.log(params);
            JobService.updateJobStatsus(params)
                .then(res => {
                    this.loading = false;
                    this.dialogVisible = false;
                    shortTips("职位状态修改成功");
                    this.$emit('update-item', this.checkedArr[0]);
                })
                .catch(err => {
                    console.log(err);
                    this.loading = false;
                });
        }
    }
};
</script>
<style lang="scss" scoped>
.status-content-wrap {
    font-size: 14px;
    color: #444;
    .content-text {
        line-height: 19px;
        > span {
            color: $primary;
        }
        i{
           color: #ff9579;
        }
        >b{
            font-weight: normal;
            font-style: normal;
        }
    }
    .items-row {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        .options-label {
            color: #666;
            margin-right: 20px;
        }

        &.bind-project-row /deep/ {
            .el-select {
                padding: 0;
            }
            .el-input .el-input__inner, .el-input__icon {
                height: 28px;
                line-height: 28px;
            }
        }

        .bind-department-tip {
            color: #F56C6C;
            margin: 0;
        }

        .add-customer-btn {
            display: flex;
            align-items: center;
            height: 28px;
            margin-left: 15px;
            font-size: 15px;
            color: $primary;
            cursor: pointer;

            > i {
                font-size: 22px;
                margin-right: 6px;
                line-height: 28px;
            }

            > em {
                vertical-align: top;
            }
        }
    }
    /deep/ .el-radio-button.red-fill {
        &.is-active {
            .el-radio-button__inner {
                background-color: #ff6564;
                border-color: #ff6564;
                box-shadow: -1px 0 0 0 #ff6564;
            }
        }
    }
}

</style>
