var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      attrs: {
        title: "修改职位状态",
        visible: _vm.dialogVisible,
        width: _vm.type == "single" ? "480px" : "600px",
        "show-close": false,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "status-content-wrap",
        },
        [
          _c("p", { staticClass: "content-text" }, [
            _vm._v("\n            你正在修改"),
            _c("span", [_vm._v(_vm._s(_vm.jobNames))]),
            _vm.jobLength > 1
              ? _c("b", [
                  _vm._v("等"),
                  _c("i", [_vm._v(_vm._s(_vm.jobLength))]),
                  _vm._v("个职位"),
                ])
              : _vm._e(),
            _vm._v("的职位状态\n        "),
          ]),
          _c(
            "div",
            { staticClass: "items-row" },
            [
              _c("span", { staticClass: "options-label" }, [
                _vm._v("职位状态"),
              ]),
              _c(
                "el-radio-group",
                {
                  attrs: { size: "mini" },
                  on: { input: _vm.handleSelectStatus },
                  model: {
                    value: _vm.jobStatusValue,
                    callback: function ($$v) {
                      _vm.jobStatusValue = $$v
                    },
                    expression: "jobStatusValue",
                  },
                },
                [
                  _vm.type == "batch"
                    ? _c("el-radio-button", { attrs: { label: -1 } }, [
                        _vm._v("\n                    不变\n                "),
                      ])
                    : _vm._e(),
                  _vm._l(
                    _vm.type == "batch"
                      ? _vm.jobStatusOptionsBatch
                      : _vm.jobStatusOptions,
                    function (option, index) {
                      return _c(
                        "el-radio-button",
                        { key: index, attrs: { label: option.value } },
                        [_vm._v(_vm._s(option.text))]
                      )
                    }
                  ),
                ],
                2
              ),
            ],
            1
          ),
          _vm.isCFUser &&
          !_vm.showBindDepartmentTip &&
          _vm.jobStatusValue == 1 &&
          _vm.hasBindList.length == 0
            ? _c(
                "div",
                { staticClass: "items-row bind-project-row" },
                [
                  _c("span", { staticClass: "options-label" }, [
                    _vm._v("绑定项目"),
                  ]),
                  _c("project-select", {
                    ref: "projectSelect",
                    staticClass: "col-4",
                    attrs: { placeholder: "请输入所属项目" },
                    on: { projectSelected: _vm.changeProject },
                  }),
                  _c(
                    "span",
                    {
                      staticClass: "add-customer-btn",
                      on: {
                        click: function ($event) {
                          return _vm.$emit("showProjectCreateDialog")
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "el-icon-circle-plus-outline" }),
                      _c("em", [_vm._v("添加项目")]),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.showBindDepartmentTip
            ? _c("div", { staticClass: "items-row bind-project-row" }, [
                _c("span", { staticClass: "options-label" }, [
                  _vm._v("绑定项目"),
                ]),
                _c("span", { staticClass: "bind-department-tip" }, [
                  _c("i", { staticClass: "el-icon-warning" }),
                  _vm._v(
                    "\n                请先为职位绑定部门！\n            "
                  ),
                ]),
              ])
            : _vm._e(),
          _c(
            "div",
            { staticClass: "items-row" },
            [
              _c("span", { staticClass: "options-label" }, [
                _vm._v("紧急程度"),
              ]),
              _c(
                "el-radio-group",
                {
                  attrs: { size: "mini" },
                  model: {
                    value: _vm.emergencyValue,
                    callback: function ($$v) {
                      _vm.emergencyValue = $$v
                    },
                    expression: "emergencyValue",
                  },
                },
                [
                  _vm.type == "batch"
                    ? _c("el-radio-button", { attrs: { label: -1 } }, [
                        _vm._v("\n                    不变\n                "),
                      ])
                    : _vm._e(),
                  _vm._l(_vm.emergencyOptions, function (option, index) {
                    return _c(
                      "el-radio-button",
                      {
                        key: index,
                        class: { "red-fill": option.value },
                        attrs: { label: option.value },
                      },
                      [_vm._v(_vm._s(option.text))]
                    )
                  }),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "items-row" },
            [
              _c("span", { staticClass: "options-label" }, [
                _vm._v("难易程度"),
              ]),
              _c(
                "el-radio-group",
                {
                  attrs: { size: "mini" },
                  model: {
                    value: _vm.difficultyValue,
                    callback: function ($$v) {
                      _vm.difficultyValue = $$v
                    },
                    expression: "difficultyValue",
                  },
                },
                [
                  _vm.type == "batch"
                    ? _c("el-radio-button", { attrs: { label: -1 } }, [
                        _vm._v("\n                    不变\n                "),
                      ])
                    : _vm._e(),
                  _vm._l(_vm.difficultyOptions, function (option, index) {
                    return _c(
                      "el-radio-button",
                      {
                        key: index,
                        class: { "red-fill": option.value },
                        attrs: { label: option.value },
                      },
                      [_vm._v(_vm._s(option.text))]
                    )
                  }),
                ],
                2
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v("取消")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", disabled: _vm.showBindDepartmentTip },
              on: { click: _vm.updateJobStatus },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }